@font-face {
    font-family: 'SangBleu Sunrise';
    src: url('../fonts/SangBleuSunrise-Light.woff2') format('woff2'),
        url('../fonts/SangBleuSunrise-Light.woff') format('woff'),
        url('../fonts/SangBleuSunrise-Light.svg#SangBleuSunrise-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Haas Grot Disp 55 Roman';
    src: url('../fonts/HaasGrotDisp-55Roman.woff2') format('woff2'),
        url('../fonts/HaasGrotDisp-55Roman.woff') format('woff'),
        url('../fonts/HaasGrotDisp-55Roman.svg#HaasGrotDisp-55Roman') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'XXXNeueHaasGroteskText Pro';
    src: url('../fonts/NHaasGroteskTXPro-75Bd.woff2') format('woff2'),
        url('../fonts/NHaasGroteskTXPro-75Bd.woff') format('woff'),
        url('../fonts/NHaasGroteskTXPro-75Bd.svg#NHaasGroteskTXPro-75Bd') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}



article,aside,details,figcaption,figure,footer,header,hgroup,main,nav,section,summary{display:block;}audio,canvas,video{display:inline-block;}audio:not([controls]){display:none;height:0;}[hidden]{display:none;}html{font-family:sans-serif;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;}body{margin:0;}a:focus{outline:thin dotted;}a:active,a:hover{outline:0;}h1{font-size:2em;margin:0.67em 0;}abbr[title]{border-bottom:1px dotted;}b,strong{font-weight:bold;}dfn{font-style:italic;}hr{-moz-box-sizing:content-box;box-sizing:content-box;height:0;}mark{background:#ff0;color:#000;}code,kbd,pre,samp{font-family:monospace,serif;font-size:1em;}pre{white-space:pre-wrap;}q{quotes:"\201C" "\201D" "\2018" "\2019";}small{font-size:80%;}sub,sup{font-size:75%;line-height:0;position:relative;vertical-align:baseline;}sup{top:-0.5em;}sub{bottom:-0.25em;}img{border:0;}svg:not(:root){overflow:hidden;}figure{margin:0;}fieldset{border:1px solid #c0c0c0;margin:0 2px;padding:0.35em 0.625em 0.75em;}legend{border:0;padding:0;}button,input,select,textarea{font-family:inherit;font-size:100%;margin:0;}button,input{line-height:normal;}button,select{text-transform:none;}button,html input[type="button"],input[type="reset"],input[type="submit"]{-webkit-appearance:button;cursor:pointer;}button[disabled],html input[disabled]{cursor:default;}input[type="checkbox"],input[type="radio"]{box-sizing:border-box;padding:0;}input[type="search"]{-webkit-appearance:textfield;-moz-box-sizing:content-box;-webkit-box-sizing:content-box;box-sizing:content-box;}input[type="search"]::-webkit-search-cancel-button,input[type="search"]::-webkit-search-decoration{-webkit-appearance:none;}button::-moz-focus-inner,input::-moz-focus-inner{border:0;padding:0;}textarea{overflow:auto;vertical-align:top;}table{border-collapse:collapse;border-spacing:0;}
*,
*::after,
*::before {
	box-sizing: border-box;
}
a:hover {
	transition: all 200ms ease-out;
}
:root {
	font-size: 16px;
}

html, body {
	height: 100%;
}

body {
	--color-text: #262523;
    --color-bg: #efece5;
    --color-link: #eca324;
    --color-link-hover: #262523;
    --color-title: #ffffff;
    --img-maxwidth: 250px;
    --blendmode-title: difference;
    --filter-img: none;
	color: var(--color-text);
	font-size: 16px;
	line-height: 27px;
	background-color: var(--color-bg);
	font-family: 'Haas Grot Disp 55 Roman', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.splash {
	--color-text: #fff;
    --color-bg: #000;
    --color-link: #f0f0f0;
    --color-link-hover: #fff;
    --color-title: #fff;
    --img-maxwidth: none;
    --blendmode-title: overlay;
}

/* Page Loader */
.js .loading::before {
	content: '';
	position: fixed;
	z-index: 100000;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: var(--color-bg);
}

.js .loading::after {
	content: '';
	position: fixed;
	z-index: 100000;
	top: 50%;
	left: 50%;
	width: 60px;
	height: 60px;
	margin: -30px 0 0 -30px;
	pointer-events: none;
	border-radius: 50%;
	opacity: 0.4;
	background: var(--color-link);
	animation: loaderAnim 0.7s linear infinite alternate forwards;
}

@keyframes loaderAnim {
	to {
		opacity: 1;
		transform: scale3d(0.5,0.5,1);
	}
}

a {
	text-decoration: none;
	color: var(--color-link);

}

a:hover,
a:focus {
	color: var(--color-link-hover);
	outline: 0;
}

.message {
	padding: 1rem;
	color: var(--color-bg);
	background: var(--color-text);
	text-align: center;
	font-weight: bold;
}

.frame {
	padding: 1rem;
	text-align: center;
	position: relative;
	z-index: 1000;
	grid-area: 1 / 1 / 2 / 2;
	align-self: start;
}

.frame__title {
	font-size: 1rem;
	margin: 0 0 1rem;
}

.frame__links {
	display: inline;
}

.frame__github,
.frame__links a:not(:last-child),
.frame__demos a:not(:last-child) {
	margin-right: 1rem;
}

.frame__demos {
	margin: 1rem 0;
}

.frame__demo--current,
.frame__demo--current:hover {
	color: var(--color-text);
	text-decoration: line-through;
	letter-spacing: 40px;
}

.frame__pagetitle {
	margin: 3rem 0 0 0;
}

.frame__pagetitle span {
	display: block;
	font-weight: normal;
	font-size: 0.85rem;
}

.frame__pagetitle span::before {
	content: '\2015   ';
}

.content {
	height: 300px;
	position: relative;
	display: flex;
    justify-content: start;
    padding: 0 8%;
	align-items: center;
	isolation: isolate;
}
.content:before {
	content: '';
	position: fixed;
	z-index: 0;
	top: 0%;
	bottom: 0%;
	left: 0%;
	right: 0%;
	background: rgba(0, 0, 0, 0.55);
}
.image-wrapper {
    z-index: -1;
}
.content__title {
	position: relative;
	z-index: 10000;
	width: 70%;
	max-width: 100%;
	/* mix-blend-mode: var(--blendmode-title);
	-webkit-text-stroke: 2px var(--color-title);
	text-stroke: 2px var(--color-title);
	-webkit-text-fill-color: transparent;
	text-fill-color: transparent;
	color: transparent;
	pointer-events: none; */
}
.title.main-title {
	position: relative;
	z-index: 10000;
	width: 70%;
	max-width: 100%;
}
.about-text {
    width: 410px;
	max-width: 100%;
	margin-bottom: 2.5rem;
}
.title {
	font-family: 'SangBleu Sunrise', serif;
	font-weight: 300;
	font-size: 80px;
	line-height: 88px;
    margin-top: 0;
    margin-bottom: 2.5rem;
}
.content__img {
	max-width: var(--img-maxwidth);
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
	will-change: transform;
	filter: var(--filter-img);
}

.content__img--full {
	width: 100%;
	height: 100%;
	background-size: cover;
	max-width: unset;
}
.frame__pagetitle {
    font-family: 'XXXNeueHaasGroteskText Pro';
}

  a.frame__portfolio.portfolio-link {
    position: relative;
    padding-left: 40px;
	line-height: 30px;
}
.arrows-wrap {
    display: inline-block;
    width: 32px;
    height: 32px;
    position: absolute;
    left: 0;
    margin-left: 0px;
    bottom: 0;
    border-radius: 60px;
    border: 2px solid #FFF;
    padding: 0px;
    text-align: center;
    line-height: 40px;
}
.arrows {
	height: 28px;
    width: 18px;
    position: absolute;
    left: 5px;
    margin-left: 0px;
    top: 0px;
    padding: 4px 3px;
    display: inline-block;
}

.arrows path {
	  stroke: #FFF;
	  fill: transparent;
	  stroke-width: 5px;
}
.portfolio-link:hover .arrows path {
	animation: arrow 1.2s infinite;
	-webkit-animation: arrow 1.2s infinite;
}

.portfolio-link .arrows path.a1,
.portfolio-link .arrows path.a3 {
	opacity: 0;
	transition: opacity .1s linear;
}
.portfolio-link:hover .arrows path.a1,
.portfolio-link:hover .arrows path.a3 {
	opacity: 1;
}

.modal-popup {
    position: fixed;
    top: 0;
    background: #FFF;
    color: #000;
    padding: 4rem;
    z-index: 999999;
	width: 920px;
	max-width: 90%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -49.3333%);
}
.modal-popup p {
    width: 60%;
}
.modal-popup .footer-modal {
	border-top: 1px solid #000;
	margin-top: 40px;
	padding-top: 20px;
	display: flex;
    justify-content: space-between;
}
.modal-popup .footer-modal .circle-link {
    color: #000;
	width: 30px;
	line-height: 28px;
	display: inline-block;
	position: relative;
    text-align: center;
    margin-left: 4px;
	border-radius: 50%;
	transition: all 250ms ease-out;
}
.modal-popup .footer-modal .circle-link:hover {
	color: #FFF;
}
.modal-popup .footer-modal .circle-link:after {
	content: '';
	width: 30px;
	height: 30px;
	display: inline-block;
	position: absolute;
    left: 0%;
    top: -2px;
    z-index: -1;
	border-radius: 60px;
	background: #000;
	transform: scale(0);
	transition: all 250ms ease-out;
}
.modal-popup .footer-modal .circle-link:hover:after {
	transform: scale(1);
}
@keyframes arrow
{
0% {opacity:0}
40% {opacity:1}
80% {opacity:0}
100% {opacity:0}
}

@-webkit-keyframes arrow /*Safari and Chrome*/
{
0% {opacity:0}
40% {opacity:1}
80% {opacity:0}
100% {opacity:0}
}


.portfolio-link:hover .arrows path.a1 {
	animation-delay:-0.6s;
	-webkit-animation-delay:-0.6s; /* Safari 和 Chrome */
}

.portfolio-link:hover .arrows path.a2 {
animation-delay:-0.3s;
-webkit-animation-delay:-0.3s; /* Safari 和 Chrome */
}
.portfolio-link:hover .arrows path.a3 {
	animation-delay:0s;
	-webkit-animation-delay:0s; /* Safari 和 Chrome */
}
.frame__contact-us .contact-wrap {
	display: flex;
	justify-content: flex-end;
	flex-wrap: wrap;
}
.frame__contact-us a {
	display: block;
	line-height: 30px;
	white-space: nowrap;
}
.frame__contact-us a + a {
	margin-left: 3rem;
}
.how-we-do {
	text-transform: uppercase;
	font-size: 12px;
	letter-spacing: 3px;
}
.how-we-do label {
	font-family: 'XXXNeueHaasGroteskText Pro', serif;
	display: block;
}
.how-we-do p {
	margin-top: 0;
}

section.content__item.content__item--about {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    justify-content: inherit;
}
video {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
	bottom: 0;
	z-index: -1;
	max-width: 100vw;
	object-fit: cover;
}
.no-touch video {
	display: none;
}
.frame__pagetitle {
	grid-area: pagetitle;
	margin: 0;
}
.frame__contact-us {
	grid-area: contact;
	margin: 0;
}
.frame__download-portfolio {
	grid-area: portfolio;
	display: flex;
}
.splitting .word {
	overflow: hidden;
	line-height: 1.25;
}
.about-text,
.how-we-do {
	z-index: 9;
}
a.frame__about {
	opacity: 0;
	pointer-events: none;
}
.close-modal {
    position: absolute;
    right: 2rem;
	top: 2rem;
	width: 30px;
	height: 30px;
	display: block;
	z-index: 99;
	overflow: hidden;
}
.close-modal:before,
.close-modal:after {
	content: '';
	width: 1px;
	height: 30px;
	position: absolute;
	top: 0;
	left: 50%;
	background: #000;
}
.close-modal:before {
	transform: rotate(-45deg);
}
.close-modal:after {
	transform: rotate(45deg);
}

.frame__download-portfolio {
	grid-area: portfolio;
	display: flex;
	align-self: end;
}
.contact-wrap a {
	position: relative;
}
.contact-wrap a:before {
	content: '';
	background: rgba(255, 255, 255, .8);
	position: absolute;
	height: 2px;
	top: 50%;
	margin-top: 0px;
	width: calc(100% + 20px);
	left: -10px;
	max-width: 0%;
	transition: all 450ms ease-out ;
}
.contact-wrap a:hover:before {
	max-width: calc(100% + 20px);
}

/* .contact-wrap a:after {
	content: '';
	background: rgba(255, 255, 255, 0.3);
    position: absolute;
    top: -50%;
    left: -40px;
    width: calc(100% + 80px);
    height: 120px;
    border-radius: 50px 30px / 27px 50px;
    border: 1px solid #ffffff7d;
	overflow: visible;
	opacity: 0;
	transition: all 800ms ease-out;
}
.contact-wrap a:hover:after {
	/* transform: scale(1);
	opacity: 1;
	transition: all 700ms ease-out;
	animation:spin 3s linear infinite;
} */
/* @-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } } */
@keyframes spin {
	25% {
		border-radius: 50%;
		/* height: 220px; */
	}
	50% {
		/* border-radius: 100px 30px / 27px 20px; */
		height: 180px;
		top: -10px;
		height: 200px;
		/* transform: rotate(90deg); */
	}

	100% {
		/* transform: rotate(180deg); */
		transform: rotate(180deg);
		/* height: 60px; */
		border-radius: 50px 30px / 27px 50px;

	}

	}


@media screen and (min-width: 53em) {
	:root {
		font-size: 18px;
	}
	body {
		overflow: hidden;
	}
	.message {
		display: none;
	}
	.frame {
		position: fixed;
		text-align: left;
		z-index: 10000;
		top: 0;
		left: 0;
		display: grid;
		align-content: space-between;
		width: 100%;
		max-width: none;
		height: 100vh;
		padding: 2rem 8%;
		pointer-events: none;
		grid-template-columns: 75% 25%;
		grid-template-rows: auto auto auto;
		grid-template-areas: 'pagetitle about'
							'... ...'
							'portfolio contact';
	}

	.frame__title {
		margin: 0;
		font-weight: normal;
	}

	.frame a {
		pointer-events: auto;
	}
	.content {
		height: 100vh;
		overflow: hidden;
	}
}

@media screen and (max-width: 1440px) {
	.title.main-title {
		width: 80%;
		font-size: 60px;
		line-height: 40px;

	}
}
@media screen and (max-width: 1400px) {
	.frame {
		grid-template-columns: 50% 50%;
	}
	.title {
		font-size: 60px;
		line-height: 40px;
	}
}
@media screen and (max-width: 1200px) {
	.title.main-title {
		width: 85%;
		font-size: 52px;
		margin-bottom: 20px;
		line-height: 30px;
	}
}
@media screen and (max-width: 900px) {


	.title.main-title {
		font-size: 46px;
		width: 100%;
	}
	.content {
		width: 100%;
	}
	section.content__item.content__item--about {
		width: 100%;
	}
}
@media (orientation: landscape) {
	#video.ismobile {
		display: none;
	}
}
@media (orientation: portrait) {
	#video.istablet{
		display: none;
	}
}

@media (orientation: portrait) and (max-width: 992px) {
	.frame {
		position: fixed;
		text-align: left;
		z-index: 10000;
		top: 0;
		left: 0;
		display: grid;
		align-content: space-between;
		width: 100%;
		max-width: none;
		height: 100vh;
		padding: 2rem 8%;
		grid-template-columns: 40% 60%;
		grid-template-rows: auto auto 50% auto;
		grid-template-areas:
			'pagetitle ...'
			'... ...'
			'... ...'
			'portfolio contact';
	}
	.content {
		height: 100vh;
	}
}
@media (orientation: portrait) and (max-width: 767px) {
	.frame {
		position: fixed;
		text-align: left;
		z-index: 10000;
		top: 0;
		left: 0;
		display: grid;
		align-content: space-between;
		width: 100%;
		max-width: none;
		height: 100vh;
		padding: 2rem 8%;
		grid-template-columns: 100% 0;
		grid-template-rows: auto auto 50% auto;
		grid-template-areas:
			'pagetitle'
			'...'
			'portfolio'
			'contact';
	}
	.frame__contact-us .contact-wrap {
		display: flex;
		justify-content: space-between;
	}
	.modal-popup .title {
		margin-top: 80px;
	}
}
@media (orientation: landscape) and (max-width: 767px) {
	.frame {
		position: fixed;
		text-align: left;
		z-index: 10000;
		top: 0;
		left: 0;
		display: grid;
		align-content: space-between;
		width: 100%;
		max-width: none;
		height: 100vh;
		padding: 2rem 8%;
		grid-template-columns: 40% 60%;
		grid-template-rows: auto auto 50% auto;
		grid-template-areas:
			'pagetitle ...'
			'... ....'
			'... ...'
			'portfolio contact';
		}
		.frame__contact-us .contact-wrap {
			display: flex;
			justify-content: flex-end;
		}
		.about-text {
			margin-bottom: 0;
			margin-top: 0;
		}
		.modal-popup .title {
			margin-top: 20px;
		}
}
@media screen and (max-width:767px) {

	.modal-popup {
		top: 0;
		left: 0%;
		bottom: 0;
		max-width: 100%;
		transform: unset;
		display: flex;
		flex-wrap: wrap;
		padding: 4rem 2rem;
	}
	.modal-popup .footer-modal {
		width: 100%;
	}
	.modal-popup .title {
		font-size: 40px;
		line-height: 48px;
		margin-bottom: 0;
	}
	.modal-popup p {
		width: 100%;
	}

	.how-we-do {
		display: none;
	}


	.content {
		height: 100vh;
	}

	.title.main-title {
		font-size: 30px;
		line-height: 24px;
		margin-bottom: 10px;
	}

	.frame {
		padding: 30px 8%;
	}
	h2.frame__pagetitle {
		margin-top: 10px;
		text-align: left;
		margin-bottom: 10px;
	}

}


.is-touch .image-wrapper {
	display: none;
}